import React, { useState, useMemo, useRef } from 'react'
import {
  Accordion,
  Table,
  Flex,
  Box,
  Spinner,
  Empty,
  Label,
  Tag,
} from '@beachfront/ui'
import { useTableQuery } from '@beachfront/ui/hooks'
import PropTypes from 'prop-types'

import { useTargetingInsight, useIntersectionObserver } from '../../../hooks'
import {
  isNotEmptyArray,
  getErrorMessage,
  formatCount,
  formatCurrency,
  toTitleCase,
} from '../../../utils'
import { NetworkError } from '../../../components'

const TargetingInsights = ({
  widgetsData,
  isLoading,
  targetingParams,
  timezone,
  minCpm,
  maxCpm,
  marginType,
  marginValue,
  dealFloor,
  dataCost,
  priceRange,
  subPriceRange,
  priceRangeEnabled,
  onClearSubPriceRange,
}) => {
  const [expandKeys, setExpandKeys] = useState([])

  const currentPriceRange = priceRangeEnabled
    ? subPriceRange ?? priceRange
    : null

  const isWidgetVisible = (widget) => {
    if (
      targetingParams.domainMedia ||
      targetingParams.bundleMedia ||
      targetingParams.bundle ||
      targetingParams.domain
    ) {
      if (widget.apiUrl?.includes('domain')) {
        return targetingParams.domainMedia || targetingParams.domain
      }
      if (widget.apiUrl?.includes('bundle')) {
        return targetingParams.bundleMedia || targetingParams.bundle
      }
    }
    return true
  }

  if (isLoading) {
    return <Spinner />
  }

  if (!widgetsData?.length) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description='No Insight Data Found'
      />
    )
  }

  return (
    <>
      {priceRangeEnabled ? (
        <InsightHeader
          minCpm={minCpm}
          maxCpm={maxCpm}
          priceRange={priceRange}
          subPriceRange={subPriceRange}
          onClear={onClearSubPriceRange}
        />
      ) : null}
      <Accordion
        type='multiple'
        value={expandKeys}
        onValueChange={setExpandKeys}
      >
        {widgetsData?.map((w) => {
          const key = w.dimension
          return isWidgetVisible(w) ? (
            <Accordion.Panel
              key={key}
              value={key}
              header={String(w.value).trim()}
            >
              <InsightTable
                widget={w}
                targetingParams={targetingParams}
                timezone={timezone}
                priceRange={currentPriceRange}
                marginType={marginType}
                marginValue={marginValue}
                dealFloor={dealFloor}
                dataCost={dataCost}
              />
            </Accordion.Panel>
          ) : null
        })}
      </Accordion>
    </>
  )
}

const InsightHeader = ({
  minCpm,
  maxCpm,
  priceRange,
  subPriceRange,
  onClear,
}) => {
  const isDefault = priceRange[0] === minCpm && priceRange[1] === maxCpm

  return (
    <>
      {!isDefault ? (
        <Flex mb={3} gap={2} flexWrap='wrap' alignItems='flex-end'>
          <Label m={0}>Showing Insights for price range</Label>
          <Tag disabled={!!subPriceRange}>
            {formatCurrency(priceRange[0])}
            {' - '}
            {formatCurrency(priceRange[1])}
          </Tag>
          {subPriceRange ? (
            <Tag onClose={onClear} closable>
              {formatCurrency(subPriceRange[0])}
              {' - '}
              {formatCurrency(subPriceRange[1])}
            </Tag>
          ) : null}
        </Flex>
      ) : null}
    </>
  )
}

InsightHeader.propTypes = {
  minCpm: PropTypes.number,
  maxCpm: PropTypes.number,
  priceRange: PropTypes.array,
  subPriceRange: PropTypes.array,
  onClear: PropTypes.func,
}

const InsightTable = ({
  widget,
  targetingParams,
  timezone,
  priceRange,
  marginType,
  marginValue,
  dealFloor,
  dataCost,
}) => {
  const bodyRef = useRef()
  const [enabled, setEnabled] = useState(false)

  const query = useTargetingInsight({
    params: {
      widget,
      targetingParams,
      timezone,
      priceRange,
      marginType,
      marginValue,
      dealFloor,
      dataCost,
    },
    enabled,
  })

  const columns = useMemo(() => getTableColumns(query.data), [query.data])
  const rows = useMemo(() => getTableRows(query.data), [query.data])

  const table = useTableQuery({
    data: rows ?? [],
  })

  useIntersectionObserver({
    element: bodyRef.current,
    onIntersect: (entries) => {
      const [entry] = entries
      setEnabled(entry.isIntersecting)
    },
  })

  if (query.error) {
    return (
      <NetworkError
        py={3}
        description={getErrorMessage(query.error)}
        onAction={query.refetch}
      />
    )
  }

  return (
    <Box bg='bg.component'>
      <Table
        rowKey='key0'
        bodyRef={bodyRef}
        emptyText={query.data?.msg ?? 'No Data Found'}
        dataSource={table.data}
        columns={columns}
        loading={query.isLoading}
        pagination={{
          ...table.query.pagination,
          hideOnSinglePage: table.data.length <= 25,
          total: table.total,
        }}
        onChange={(e) => {
          table.setPagination(e.pagination)
          table.setSorter(e.sorter)
        }}
      />
    </Box>
  )
}

InsightTable.propTypes = {
  widget: PropTypes.object,
  targetingParams: PropTypes.object,
  timezone: PropTypes.string,
  priceRange: PropTypes.array,
  marginType: PropTypes.string,
  marginValue: PropTypes.number,
  dealFloor: PropTypes.number,
  dataCost: PropTypes.number,
}

const getTableColumns = (data) => {
  if (!data) {
    return []
  }
  const renderMap = {
    STRING: (val) => val || 'NA',
    NUMBER: (val) => formatCount(val),
  }
  return data.headers
    .filter((el) => el)
    .map((el, i) => ({
      dataIndex: `key${i}`,
      title: toTitleCase(el.title),
      width: el.width ?? 115,
      sorter: true,
      defaultSortOrder: el.defaultSortOrder ?? null,
      render: renderMap[el.type],
    }))
}

const getTableRows = (data) => {
  if (!data) {
    return []
  }
  return data.rows
    .filter((el) => el)
    .map((el) => {
      const record = {}
      if (isNotEmptyArray(el.data)) {
        el.data.forEach((val, i) => {
          record[`key${i}`] = val
        })
      }
      return record
    })
}

TargetingInsights.defaultProps = {
  widgetsData: [],
  targetingParams: {},
}

TargetingInsights.propTypes = {
  widgetsData: PropTypes.array,
  isLoading: PropTypes.bool,
  targetingParams: PropTypes.object,
  timezone: PropTypes.string,
  minCpm: PropTypes.number,
  maxCpm: PropTypes.number,
  marginType: PropTypes.string,
  marginValue: PropTypes.number,
  dealFloor: PropTypes.number,
  dataCost: PropTypes.number,
  priceRange: PropTypes.array,
  subPriceRange: PropTypes.array,
  priceRangeEnabled: PropTypes.bool,
  onClearSubPriceRange: PropTypes.func,
}

export default TargetingInsights
